import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCheck,
  faFileImage,
  faTimes,
} from "@fortawesome/free-solid-svg-icons";
import { faClock, faCircle } from "@fortawesome/free-regular-svg-icons";
import Modal from "react-modal";

import moment from "moment";

interface IListaApostaProps {
  data: any;
  valorComissao: any;
  valorTotal: any;
}

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    maxHeight: "500px",
    transform: "translate(-50%, -50%)",
  },
};

Modal.setAppElement("#root");

export function ListaAposta(props: IListaApostaProps) {
  let amount = 0;
  const [modalIsOpen, setIsOpen] = React.useState(false);
  const [urlImg, setUrlImg] = React.useState("");

  function openModal(aposta: any) {
    const urlImg =
      "https://s3.sa-east-1.amazonaws.com/bucket.controllerti.com.br/bets/GOLD/" +
      aposta.id365 +
      "-" +
      moment(aposta.dataAposta).format("YYYYMMDDHHmmss.jpg");
    setUrlImg(urlImg);
    setIsOpen(true);
  }

  function afterOpenModal() {
    // references are now sync'd and can be accessed.
  }

  function closeModal() {
    setIsOpen(false);
  }

  const lista = props.data.map((aposta: any) => {
    amount = amount + aposta.valorGanho;
    let statusClass = "";
    if (aposta.status === "GANHA") {
      statusClass = "ganha";
    } else if (aposta.status === "PERDIDA") {
      statusClass = "perdida";
    } else if (aposta.status === "ANULADA") {
      statusClass = "anulada";
    }

    let jsonEventos365 =
      aposta.detalhesJson365 && aposta.detalhesJson365.length > 0
        ? JSON.parse(aposta.detalhesJson365)
        : null;

    if (aposta.moralInfo && aposta.moralInfo.idMoral) {
      jsonEventos365 = JSON.parse(aposta.moralInfo.detalhesJson);
    }

    let listaEventosEspelho = null;

    if (aposta && aposta.detalhes365) {
      listaEventosEspelho = aposta.detalhes365.map((detalhe: any, i: any) => {
        return (
          <tr key={i}>
            <td>
              {"- " +
                detalhe.evento +
                " (" +
                detalhe.evento_data +
                ")" +
                " - " +
                detalhe.tipo +
                " - " +
                detalhe.opcao +
                " @ " +
                detalhe.odds +
                " - " +
                detalhe.status}
            </td>
          </tr>
        );
      });
    } else {
      listaEventosEspelho =
        jsonEventos365 !== null
          ? jsonEventos365.map((detalhe: any, i: any) => {
              return (
                <tr key={i}>
                  <td>
                    {"- " +
                      detalhe.detail_event +
                      " - " +
                      detalhe.detail_type +
                      " - " +
                      detalhe.detail_option +
                      " @ " +
                      detalhe.detail_odds +
                      " - " +
                      detalhe.detail_status}
                  </td>
                </tr>
              );
            })
          : null;
    }

    const listaEventos = aposta.detalhes.map((detalhe: any) => {
      let icon = <FontAwesomeIcon icon={faClock} />;
      if (detalhe.status === "GANHA") {
        icon = <FontAwesomeIcon className="ganha" icon={faCheck} />;
      } else if (detalhe.status === "PERDIDA") {
        icon = <FontAwesomeIcon className="perdida" icon={faTimes} />;
      } else if (detalhe.status === "ANULADA") {
        icon = <FontAwesomeIcon className="anulada" icon={faCircle} />;
      }
      let retorno = "";
      if (detalhe.competicao && !detalhe.evento) {
        retorno = detalhe.competicao.nome + " - ";
      }

      if (detalhe.evento) {
        retorno =
          retorno + detalhe.evento.casa.nome + " x " + detalhe.evento.fora.nome;
      }
      let aovivo = "";
      if (detalhe.placarAtual) {
        aovivo = "(" + detalhe.placarAtual + ")";
      }

      let opcao = "";
      if (detalhe.opcao) {
        if (!detalhe.tipo) {
          opcao = detalhe.descricao;
        }
        if (detalhe.opcao && detalhe.opcao.template > 0) {
          opcao = opcao + " - " + detalhe.opcao.descricao;
        }
        if (detalhe.opp) {
          opcao = opcao + " (" + detalhe.opp + ")";
        }
      } else {
        if (!detalhe.tipo) {
          opcao = detalhe.descricao;
        }
      }

      let tipoAposta = detalhe.tipo
        ? detalhe.tipo.descricao + " " + aovivo
        : "";

      if (detalhe.evento) {
        opcao = opcao.replace(/Casa/g, detalhe.evento.casa.nome);
        opcao = opcao.replace(/Fora/g, detalhe.evento.fora.nome);

        if (
          ["Basquete", "Futebol Americano"].includes(
            detalhe.evento.competicao.esporte.nome
          )
        ) {
          tipoAposta = tipoAposta.replace(/Gols/g, "Pontos");
        }
      }
      const tipo = detalhe.tipo ? " - " + tipoAposta + opcao : " - " + opcao;

      return (
        <tr key={detalhe.id}>
          <td>
            {retorno +
              tipo +
              " @ " +
              detalhe.odds.toLocaleString("en", {
                minimumFractionDigits: 2,
                maximumFractionDigits: 4,
              })}{" "}
            {icon}
          </td>
        </tr>
      );
    });

    if (!aposta.id365) {
      aposta.id365 = "";
    }

    return (
      <tr key={aposta.id} className={statusClass}>
        <td className="comBorda">
          {moment(aposta.dataAposta).format("DD/MM/YYYY HH:mm") +
            " #" +
            aposta.id +
            " " +
            aposta.id365 +
            " "}

          {aposta.id365 && (
            <button onClick={() => openModal(aposta)} about="_blank">
              <FontAwesomeIcon icon={faFileImage} />
            </button>
          )}
        </td>
        {/* <td className='comBorda'>{aposta.moralInfo != null ? 'Site' : ''}</td> */}
        <td className="comBorda">
          {aposta.valor.toLocaleString("en", { minimumFractionDigits: 2 })}
        </td>
        <td className="comBorda">
          {aposta.odds
            ? aposta.odds.toLocaleString("en", {
                minimumFractionDigits: 2,
                maximumFractionDigits: 4,
              })
            : ""}
        </td>
        <td className="comBorda">
          <table className="semBorda listaDetalhe">
            <tbody>
              {listaEventos}
              {listaEventosEspelho}
            </tbody>
          </table>
        </td>
        {/* <td className='comBorda'>{aposta.status}</td> */}
        {props.valorComissao !== 0 && (
          <td className="comBorda">
            {aposta.comissao
              ? aposta.comissao.toLocaleString("en", {
                  minimumFractionDigits: 2,
                })
              : 0}
          </td>
        )}
        <td className="comBorda">
          {aposta.valorGanho.toLocaleString("en", { minimumFractionDigits: 2 })}
        </td>
      </tr>
    );
  });

  return (
    <>
      <table className="comBorda listaAposta">
        <thead>
          <tr>
            <th className="comBorda">Data</th>
            {/* <th className='comBorda'>Origem</th> */}
            <th className="comBorda">Valor</th>
            <th className="comBorda">Odds</th>
            <th className="comBorda">Eventos</th>
            {/* <th className='comBorda'>Status</th> */}
            {props.valorComissao !== 0 && (
              <th className="comBorda">Comissão</th>
            )}
            <th className="comBorda">Resultado</th>
          </tr>
        </thead>
        <tbody>
          {lista}
          <tr>
            <th
              colSpan={props.valorComissao !== 0 ? 5 : 4}
              className="comBorda"
            >
              TOTAL
            </th>
            <th>
              {props.valorTotal.toLocaleString("en", {
                minimumFractionDigits: 2,
              })}
            </th>
          </tr>
        </tbody>
      </table>
      <Modal
        isOpen={modalIsOpen}
        onAfterOpen={afterOpenModal}
        onRequestClose={closeModal}
        style={customStyles}
        contentLabel="Modal"
      >
        <button onClick={closeModal}>X</button>
        <div>
          <img src={urlImg} alt="" style={{ maxWidth: "95%" }} />
        </div>
      </Modal>
    </>
  );
}
