import React from "react";

interface IListaComissionadosProps {
  data: any;
  valorComissionado: any;
}

export function ListaComissionados(props: IListaComissionadosProps) {
  const listaComissionados = props.data.map(
    (comissionado: any) => {
      const volume = comissionado.volume;
      const valor_ganho = (comissionado.valor_ganho);
      const valor_comissao = (comissionado.valor_comissao);

      return (
        <tr key={comissionado.id}>
          <td className="comBorda">{comissionado.nome}</td>
          <td className="comBorda">{comissionado.porcentagem_parceiro}%</td>
          <td className="comBorda">
            {volume.toLocaleString("en", {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            })}
          </td>
          <td className="comBorda">{comissionado.quantidade}</td>
          <td className="comBorda">
            {valor_ganho.toLocaleString("en", {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            })}
          </td>
          <td className="comBorda">
            {valor_comissao.toLocaleString("en", {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            })}
          </td>
        </tr>
      );
    }
  );

  if (listaComissionados === null || listaComissionados === undefined || listaComissionados.length === 0) {
    return null;
  }

  return (
    <><h2>Comissionados</h2><table className="comBorda listaAposta">
      <thead>
        <tr>
          <th className="comBorda">Comissionado</th>
          <th className="comBorda">% Bancada</th>
          <th className="comBorda">Volume</th>
          <th className="comBorda">Qtd</th>
          <th className="comBorda">Resultado</th>
          <th className="comBorda">Comissão</th>
        </tr>
      </thead>
      <tbody>
        {listaComissionados}
        <tr>
          <th className="comBorda" colSpan={5}>TOTAL</th>
          <th className="comBorda">
            {props.valorComissionado.toLocaleString("en", {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            })}
          </th>
        </tr>
      </tbody>
    </table></>
  );
}