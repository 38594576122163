import React from "react";

interface IHeaderProps {
  valorDesconto: any;
  valorComissao: any;
  valorPendente: any;
  valorTotal: any;
  valorFinal: any;
  valorComissionado: any;
}

export function Header(props: IHeaderProps) {
  return (
    <table className="comBorda tabelaResumo">
      <thead>
        <tr>
          {props.valorPendente !== 0 && <th className="comBorda">Total em Aberto</th>}
          <th className="comBorda">Resultado</th>
          {props.valorDesconto !== 0 && <th className="comBorda">Desconto</th>}
          {props.valorComissao !== 0 && <th className="comBorda">Comissão</th>}
          {props.valorComissionado !== 0 && <th className="comBorda">Comissionado</th>}
          <th className="comBorda">Líquido</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          {props.valorPendente !== 0 && (
            <td className="comBorda">
              {props.valorPendente.toLocaleString("en", {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              })}
            </td>
          )}
          <td className="comBorda">
            {props.valorTotal.toLocaleString("en", {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            })}
          </td>
          {props.valorDesconto !== 0 && (
            <td className="comBorda">
              {props.valorDesconto.toLocaleString("en", {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              })}
            </td>
          )}
          {props.valorComissao !== 0 && (
            <td className="comBorda">
              {props.valorComissao.toLocaleString("en", {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              })}
            </td>
          )}
          {props.valorComissionado !== 0 && (
            <td className="comBorda">
              {props.valorComissionado.toLocaleString("en", {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              })}
            </td>
          )}
          <td className="comBorda">
            {props.valorFinal.toLocaleString("en", {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            })}
          </td>
        </tr>
      </tbody>
    </table>
  );
}
