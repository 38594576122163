import React, { useEffect, useState } from "react";
import { Header } from "../Header/Header";
import "./styles.css";

interface IListaParceriaProps {
  data: any;  
  valorComissionado: any;
  valorComissionadoParceiro: any;
  cliente: any;
  valorComissionadoBanca: any;
}

export function ListaParceria(props: IListaParceriaProps) {
  const [totalComissao, setTotalComissao] = useState(0);  
  const [totalResultado, setTotalResultado] = useState(0);
  const [totalResultadoParceiro, setTotalResultadoParceiro] = useState(0);
  const [totalResultadoBanca, setTotalResultadoBanca] = useState(0);
  const [totalLiquido, setTotalLiquido] = useState(0);
  const [totalQtd, setTotalQtd] = useState(0);
  const [totalVolume, setTotalVolume] = useState(0);

  useEffect(() => {
    const comissao = props.data.reduce((total: number, currentValue: any) => {
      return (
        total +
        (currentValue.valor_desconto)
      );
    }, 0);
    const resultado = props.data.reduce((total: number, currentValue: any) => {
      return (
        total +
        (currentValue.valor_ganho)
      );
    }, 0);
    const liquido = props.data.reduce((total: number, currentValue: any) => {
      return (
        total +
        (currentValue.valor_liquido)
      );
    }, 0);
    const resultadoParceiro = props.data.reduce((total: number, currentValue: any) => {
      return (
        total +
        (currentValue.valor_liquido*currentValue.porcentagem_parceiro/100)
      );
    }, 0);
    const resultadoBanca = props.data.reduce((total: number, currentValue: any) => {
      return (
        total +
        (currentValue.valor_liquido*currentValue.porcetagemBancada/100)
      );
    }, 0);
    const tQtd = props.data.reduce((total: number, currentValue: any) => {
      return (
        total +
        (currentValue.quantidade)
      );
    }, 0);
    const tVolume = props.data.reduce((total: number, currentValue: any) => {
      return (
        total +
        (currentValue.volume)
      );
    }, 0);

    setTotalResultadoBanca(resultadoBanca);
    setTotalResultadoParceiro(resultadoParceiro);
    setTotalComissao(comissao);
    setTotalResultado(resultado);
    setTotalLiquido(liquido);
    setTotalQtd(tQtd);
    setTotalVolume(tVolume);
  }, [props]);

  const lista = props.data.map((cliente: any) => {
    let statusClass = "";
    if (cliente.valor_liquido > 0) {
      statusClass = "ganha";
    } else {
      statusClass = "perdida";
    }

    const volume = (cliente.volume);
    const valor_ganho =
      (cliente.valor_ganho);
    const valor_desconto =
      (cliente.valor_desconto);
    const valor_liquido =
      (cliente.valor_liquido);

    return (
      <tr className={statusClass} key={cliente.id}>
        <td className="comBorda">{cliente.nome}</td>
        <td className="comBorda">{cliente.porcentagem_parceiro}%</td>
        <td className="comBorda">{cliente.quantidade}</td>
        <td className="comBorda">
          {volume.toLocaleString("en", {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          })}
        </td>
        <td className="comBorda">
          {valor_ganho.toLocaleString("en", {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          })}
        </td>
        {totalComissao !== 0 && (
          <td className="comBorda">
            {valor_desconto.toLocaleString("en", {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            })}
          </td>
        )}
        <td className="comBorda">
          {valor_liquido.toLocaleString("en", {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          })}
        </td>
      </tr>
    );
  });

  const detalheParceiro = (totalResultadoParceiro+props.valorComissionadoParceiro)*-1;
  const detalheBanca = (totalResultadoBanca+props.valorComissionadoBanca)*-1;
  const detalheOutras = (totalLiquido + props.valorComissionado)*-1 - detalheParceiro - detalheBanca;

  return (
    <>
      <Header
        valorDesconto={0}
        valorComissao={totalComissao*-1}
        valorPendente={0}
        valorTotal={totalResultado*-1}
        valorFinal={(totalLiquido+props.valorComissionado)*-1}
        valorComissionado={props.valorComissionado*-1}
      />
      <div className="detalhes-liquido">
      {props.cliente.nome}: {detalheParceiro.toLocaleString("en", {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              })}
      <br />              
      BANCA: {detalheBanca.toLocaleString("en", {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              })}
              <br />      
      Outros: {detalheOutras.toLocaleString("en", {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              })}      
      </div>
      <h2>Clientes</h2>
      <table className="comBorda listaAposta">
        <thead>
          <tr>
            <th className="comBorda">Cliente</th>
            <th className="comBorda">% Bancada</th>
            <th className="comBorda">Qtd</th>
            <th className="comBorda">Volume</th>
            <th className="comBorda">Resultado</th>
            {totalComissao !== 0 && <th className="comBorda">Comissão</th>}
            <th className="comBorda">Líquido</th>
          </tr>
        </thead>
        <tbody>
          {lista}
          <tr>
            <th className="comBorda" colSpan={2}>
              TOTAL
            </th>
            <th className="comBorda">
              {totalQtd.toLocaleString("en", {
                minimumFractionDigits: 0,
                maximumFractionDigits: 0,
              })}
            </th>
            <th className="comBorda">
              {totalVolume.toLocaleString("en", {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              })}
            </th>
            <th className="comBorda">
              {totalResultado.toLocaleString("en", {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              })}
            </th>
            {totalComissao !== 0 && (
              <th className="comBorda">
                {totalComissao.toLocaleString("en", {
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2,
                })}
              </th>
            )}
            <th className="comBorda">
              {totalLiquido.toLocaleString("en", {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              })}
            </th>
          </tr>
        </tbody>
      </table>      
    </>
  );
}
