/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faChevronLeft,
  faChevronRight,
} from "@fortawesome/free-solid-svg-icons";

import "./styles.css";
import { Header } from "../Header/Header";
import { ListaAposta } from "../ListaApostas/ListaAposta";
import { ListaParceria } from "../ListaParceria/ListaParceria";
import { ListaComissionados } from "../ListaComissionados/ListaComissionados";

const moment = require("moment");
const apiUrl = process.env.REACT_APP_API_URL;

function App() {
  const [data, setData] = useState<any>([]);
  const [dataApostasSistema, setDataApostasSistema] = useState<any>([]);
  const [data365, setData365] = useState<any>([]);
  const [dataComissionado, setDataComissionado] = useState<any>([]);
  const [dataInicio, setDataInicio] = useState<Date>(getMonday(new Date()));
  const [dataFim, setDataFim] = useState<Date>(getNextSunday(new Date()));
  const [diaAtual, setDiaAtual] = useState(new Date());
  const [valorTotal, setValorTotal] = useState(0);
  const [valorTotal365, setValorTotal365] = useState(0);
  const [valorTotalApostasSistema, setValorTotalApostasSistema] = useState(0);
  const [valorDesconto, setValorDesconto] = useState(0);
  const [valorComissao, setValorComissao] = useState(0);
  const [valorComissao365, setValorComissao365] = useState(0);
  const [valorComissaoApostasSistema, setValorComissaoApostasSistema] =
    useState(0);
  const [valorFinal, setValorFinal] = useState(0);
  const [valorPendente, setValorPendente] = useState(0);
  const [uuid, setUuid] = useState("");
  const [tipo, setTipo] = useState("C");
  const [cliente, setCliente] = useState<any>({});
  const [loading, setLoading] = useState(true);
  const [totalComissionado, setTotalComissionado] = useState(0);
  const [totalComissionadoParceiro, setTotalComissionadoParceiro] = useState(0);
  const [totalComissionadoBanca, setTotalComissionadoBanca] = useState(0);

  function fetchUrl() {
    setLoading(true);
    if (dataInicio && dataFim && cliente.id) {
      const dataI = moment(dataInicio).format("DD/MM/YYYY");
      const dataF = moment(dataFim).format("DD/MM/YYYY");
      fetch(
        apiUrl +
          "v1/clientes/relatorio/" +
          cliente.id +
          "?dataInicio=" +
          dataI +
          "&dataFim=" +
          dataF
      )
        .then((response) => {
          return response.json();
        })
        .then((json) => {
          const res = json.reduce((total: number, currentValue: any) => {
            return total + currentValue.valorGanho;
          }, 0);

          const comissao = json.reduce((total: number, currentValue: any) => {
            return total + currentValue.comissao;
          }, 0);

          const pedente = json.reduce((total: number, currentValue: any) => {
            return (
              total +
              (currentValue.status === "PENDENTE" ? currentValue.valor : 0)
            );
          }, 0);

          let descontoApostaPerdida = 0;
          if (cliente.descontoApostaPerdida > 0) {
            descontoApostaPerdida = json.reduce(
              (total: number, currentValue: any) => {
                return (
                  total +
                  (currentValue.status === "PERDIDA"
                    ? ((currentValue.valorGanho *
                        cliente.descontoApostaPerdida) /
                        100) *
                      -1
                    : 0)
                );
              },
              0
            );
          }

          let desconto = 0;

          if (cliente.defesa) {
            desconto =
              descontoApostaPerdida +
              (cliente.desconto > 0 && res > 0
                ? ((res * cliente.desconto) / 100) * -1
                : 0);
          } else {
            desconto =
              descontoApostaPerdida +
              (cliente.desconto > 0 && res < 0
                ? ((res * cliente.desconto) / 100) * -1
                : 0);
          }

          const json365 = json.filter(
            (d: any) =>
              (d.detalhesJson365 && d.detalhesJson365.length > 2) || d.id365
          );
          const jsonApostasSistema = json.filter(
            (d: any) =>
              (!d.detalhesJson365 || d.detalhesJson365.length <= 2) && !d.id365
          );

          if (json365 && json365.length > 0) {
            const res365 = json365.reduce(
              (total: number, currentValue: any) => {
                return total + currentValue.valorGanho;
              },
              0
            );

            const comissao365 = json365.reduce(
              (total: number, currentValue: any) => {
                return total + currentValue.comissao;
              },
              0
            );

            setValorTotal365(res365);
            setValorComissao365(comissao365);
          }

          if (jsonApostasSistema && jsonApostasSistema.length > 0) {
            const resApostasSistema = jsonApostasSistema.reduce(
              (total: number, currentValue: any) => {
                return total + currentValue.valorGanho;
              },
              0
            );
            const comissaoApostasSistema = jsonApostasSistema.reduce(
              (total: number, currentValue: any) => {
                return total + currentValue.comissao;
              },
              0
            );
            setValorTotalApostasSistema(resApostasSistema);
            setValorComissaoApostasSistema(comissaoApostasSistema);
          }

          setValorPendente(pedente);
          setValorTotal(res);
          setData(json);
          setDataApostasSistema(jsonApostasSistema);
          setData365(json365);
          setValorDesconto(desconto);
          setValorComissao(comissao);
          setValorFinal(res + desconto + comissao);
          setLoading(false);
        });
    }
  }

  useEffect(() => {
    if (dataComissionado && dataComissionado.length > 0) {
      const comissao = dataComissionado.reduce(
        (total: number, currentValue: any) => {
          return total + currentValue.valor_comissao;
        },
        0
      );

      const comissaoParceiro = dataComissionado.reduce(
        (total: number, currentValue: any) => {
          return (
            total +
            (currentValue.valor_comissao * currentValue.porcentagem_parceiro) /
              100
          );
        },
        0
      );

      const comissaoBanca = dataComissionado.reduce(
        (total: number, currentValue: any) => {
          return (
            total +
            (currentValue.valor_comissao * currentValue.porcentagemBancada) /
              100
          );
        },
        0
      );

      setTotalComissionado(comissao);
      setTotalComissionadoParceiro(comissaoParceiro);
      setTotalComissionadoBanca(comissaoBanca);
    }
  }, [dataComissionado]);

  async function fetchUrlParceiro() {
    setLoading(true);

    if (dataInicio && dataFim && cliente.id) {
      await Promise.all([loadRelatorioGeralData(), loadComissionadoData()]);
    }

    setLoading(false);
  }

  async function loadRelatorioGeralData(): Promise<void> {
    const dataI = moment(dataInicio).format("DD/MM/YYYY");
    const dataF = moment(dataFim).format("DD/MM/YYYY");

    if (dataInicio && dataFim && cliente.id) {
      const response = await fetch(
        apiUrl +
          "v1/parceria/relatorio/geral/" +
          cliente.id +
          "/BRL?dataInicio=" +
          dataI +
          "&dataFim=" +
          dataF
      );
      const json = await response.json();
      setData(json);
    }
  }

  async function loadComissionadoData(): Promise<void> {
    const dataI = moment(dataInicio).format("DD/MM/YYYY");
    const dataF = moment(dataFim).format("DD/MM/YYYY");

    if (dataInicio && dataFim && cliente.id) {
      const response = await fetch(
        apiUrl +
          "v1/parceria/relatorio/gerente/" +
          cliente.id +
          "/BRL?dataInicio=" +
          dataI +
          "&dataFim=" +
          dataF
      );
      const json = await response.json();
      setDataComissionado(json);
    }
  }

  function fetchCliente() {
    const search = window.location.search;
    const params = new URLSearchParams(search);
    const id = params.get("id") || window.location.search.replace("?", "");
    const tipo = params.get("tipo") || "C";

    setUuid(id);
    setLoading(true);
    if (uuid) {
      if (tipo.toUpperCase() === "P") {
        setTipo("P");
        fetch(apiUrl + "v1/parceria/guid/" + id)
          .then((response) => {
            return response.json();
          })
          .then((json) => {
            setCliente(json);
            setLoading(false);
          });
      } else {
        fetch(apiUrl + "v1/clientes/guid/" + id)
          .then((response) => {
            return response.json();
          })
          .then((json) => {
            setCliente(json);
            setLoading(false);
          });
      }
    }
  }

  function anterior() {
    const novoDia = addDays(diaAtual, -7);
    setDiaAtual(novoDia);
    setDataInicio(getMonday(novoDia));
    setDataFim(getNextSunday(novoDia));
  }

  function proxima() {
    const novoDia = addDays(diaAtual, 7);
    setDiaAtual(novoDia);
    setDataInicio(getMonday(novoDia));
    setDataFim(getNextSunday(novoDia));
  }

  useEffect(() => {
    fetchCliente();
  }, [uuid]);

  useEffect(() => {
    if (tipo === "P") {
      fetchUrlParceiro();
    } else {
      fetchUrl();
    }
  }, [diaAtual, cliente]);

  const header =
    tipo === "C" ? (
      <Header
        valorDesconto={valorDesconto}
        valorComissao={valorComissao}
        valorPendente={valorPendente}
        valorTotal={valorTotal}
        valorFinal={valorFinal}
        valorComissionado={0}
      />
    ) : null;

  const listaApostaSistema = (
    <>
      <h2>Grupo</h2>
      <ListaAposta
        data={dataApostasSistema}
        valorComissao={valorComissaoApostasSistema}
        valorTotal={valorTotalApostasSistema}
      />
    </>
  );

  const listaAposta365 = (
    <>
      <h2>Espelho 365</h2>
      <ListaAposta
        data={data365}
        valorComissao={valorComissao365}
        valorTotal={valorTotal365}
      />
    </>
  );

  const listaAposta =
    tipo === "C" ? (
      <>
        {dataApostasSistema &&
          dataApostasSistema.length > 0 &&
          listaApostaSistema}
        {data365 && data365.length > 0 && listaAposta365}
      </>
    ) : (
      <>
        <ListaParceria
          data={data}
          valorComissionado={totalComissionado}
          valorComissionadoParceiro={totalComissionadoParceiro}
          cliente={cliente}
          valorComissionadoBanca={totalComissionadoBanca}
        />
        <br />
        <br />
        <ListaComissionados
          data={dataComissionado}
          valorComissionado={totalComissionado}
        />
      </>
    );

  const logoUrl = process.env.REACT_APP_LOGO_URL;

  return (
    <div className="container">
      {loading && <div className="loading">Loading&#8230;</div>}
      {logoUrl && (
        <div className="logo">
          <img alt="logo" src={logoUrl} width="150px" />
        </div>
      )}
      <h1>{cliente.nome}</h1>

      <h1>
        <button onClick={() => anterior()}>
          <FontAwesomeIcon icon={faChevronLeft} size={"2x"} />
        </button>
        {moment(dataInicio).format("DD/MM/YYYY")} -{" "}
        {moment(dataFim).format("DD/MM/YYYY")}
        <button onClick={() => proxima()}>
          <FontAwesomeIcon icon={faChevronRight} size={"2x"} />
        </button>
      </h1>
      {header}

      <br />
      <br />
      {listaAposta}
    </div>
  );
}

function getMonday(d: Date) {
  const day = d.getDay(),
    diff = d.getDate() - day + (day === 0 ? -6 : 1); // adjust when day is sunday
  return new Date(d.setDate(diff));
}

function getNextSunday(d: Date) {
  if (d.getDay() !== 0) {
    d.setDate(d.getDate() + ((0 - 1 - d.getDay() + 7) % 7) + 1);
  }
  return d;
}

function addDays(date: Date, days: number) {
  const result = new Date(date);
  result.setDate(result.getDate() + days);
  return result;
}

export default App;
